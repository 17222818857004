window.addEventListener('load', () => {
    try {
        jQuery('.cep-debut').mask('00000-000');
    } catch(ex) {
        console.log('Failed applying mask')
    }

    const body = document.querySelector('body');
    const form = document.querySelector('form.wpcf7-form');
    const divFormOutput = document.querySelector('.cobertura .wpcf7-response-output');
    if(typeof wpcf7 !== 'undefined') wpcf7.submit(form);

    const wpcf7FormId = document.querySelector('input[name="consulteCoberturaWPCF7ID"]').value;

    const inputCep = form.querySelector('input[name="cep"]');
    const inputCelular = form.querySelector('input[name="celular"]')
    const inputHouseNumber = form.querySelector('input[name="numero"]')
    const inputCobertura = document.querySelector('.roof-input');
    const inputRua = document.querySelector('.roof-street');
    const inputBairro = document.querySelector('.roof-district');
    const inputCidade = document.querySelector('.roof-city');
    const inputUFRoof = document.querySelector('.roof-state');

    const buttonConsultar = form.querySelector('a[class$="fake-submit"]');
    const buttonContatoNao = document.querySelector('.cobertura__btn.-not-contact');
    const buttonContatoSim = document.querySelector('.cobertura__btn.-contact');

    const hasClass = (elem, classname) => (elem.classList.contains(classname));
    const classListAdd = (elem, classes) => (Array.isArray(classes) ? classes.forEach(_class => elem.classList.add(_class)) : elem.classList.add(classes));
    const classListRemove  = (elem, classes) => (Array.isArray(classes) ? classes.forEach(_class => elem.classList.remove(_class)) : elem.classList.remove(classes));

    const formInvalidFeedback = (text, input=null) => {
        classListAdd(form, 'invalid');
        classListAdd(form, 'cep-invalid');
        if(input) classListAdd(input, 'wpcf7-not-valid');
        divFormOutput.innerHTML = text;
    }

    const formResetFeedback = () => {
        form.querySelectorAll('input').forEach(input => classListRemove(input, 'wpcf7-not-valid'));
        classListRemove(form, 'invalid');
        classListRemove(form, 'cep-invalid');
        divFormOutput.innerHTML = '';
    }

    const validateCEP = async () => {
        const cep = inputCep.value;
        if(!cep) return formInvalidFeedback('Por favor, informe o CEP do endereço.', inputCep);
        if(cep.length < 8) return formInvalidFeedback('Por favor, informe o CEP completo do endereço.', inputCep);

        const celular = inputCelular.value;
        if(!celular) return formInvalidFeedback('Por favor, informe um número de celular.', inputCelular);
        if(celular.length < 10) return formInvalidFeedback('Por favor, informe um número de celular válido.', inputCelular);

        const houseNumber = inputHouseNumber.value;
        if(!houseNumber) return formInvalidFeedback('Por favor, informe o número da residência.', houseNumber);
        
        try {
            const response = await fetch(`https://ws.apicep.com/cep/${cep}.json`)
            const json = await response.json();
            const valid = Boolean(json.status == 200);
            if(valid) {
                formResetFeedback();
                inputRua.value = json.address;
                inputBairro.value = json.district;
                inputCidade.value = json.city;
                inputUFRoof.value = json.state;
            } else {
                formInvalidFeedback('Por favor, digite um CEP válido.', inputCep);
            }
            inputCep.style.border = valid ? 'none' : '2px solid red';
            return valid;
        } catch(ex) {
            return false;
        }
    }

    const verifyViability = async () => {
        const zipCode = inputCep.value.replace(/\D/g, "");
        const phone = inputCelular.value.replace(/\D/g, "");
        const number = inputHouseNumber.value;
        // &gclid=${GCLID}
        await sendForm()
        if(!zipCode) return false;
        var url = `https://checkoutnew.verointernetfibra.com.br/?by_pass=true&postal_code=${zipCode}&number=${number}&utm_campaign=vero&phone_number=${phone}`;
        window.open(url, '_blank');
    }

    const sendForm = async () => {
        try {
            const formData = new FormData(form);
            formData.set('cep', inputCep.value.replace(/\D/g, ""));
            console.log(formData.values());
        
            const response = await fetch(`${window.location.origin}/wp-json/contact-form-7/v1/contact-forms/${wpcf7FormId}/feedback`, {
                method: 'POST',
                body: formData
            });
            const json = await response.json();
        } catch(ex){
            return false;
        } finally {
            classListRemove(buttonConsultar, '-loading');
        }
    }

    const formReset = () => {
        inputRua.value =  '';
        inputBairro.value =  '';
        inputCidade.value =  '';
        inputUFRoof.value =  '';
        inputCobertura.value =  '';
        divFormOutput.innerHTML = '';
        divFormOutput.removeAttribute('style');
        classListRemove(form, ['sent', 'invalid', 'failed', 'spam', 'cep-invalid']);
        classListRemove(body, ['withour-roof', 'with-roof', 'wanna-contact', 'dont-contact', 'sent-roof']);
        document.querySelectorAll('.cobertura__btn').forEach(el => {
            el.disabled = false;
            classListRemove(el, '-loading')
        });
        formResetFeedback();
    }

    buttonConsultar.addEventListener('click', async () => {
        if(hasClass(buttonConsultar, '-loading')) return;

        if(hasClass(body, 'with-roof')) {
            classListAdd(body, '-is-question');
        } else {
            classListAdd(buttonConsultar, '-loading');
            if(!await validateCEP()) return classListRemove(buttonConsultar, '-loading');
            await verifyViability();
        }
    });

    buttonContatoNao.addEventListener('click', async () => {
        inputCobertura.value = 'sim';
        buttonContatoSim.disabled = true;
        classListAdd(buttonContatoNao, '-loading');
        await sendForm();
    });

    buttonContatoSim.addEventListener('click', async () => {
        inputCobertura.value = 'sim me ligue';
        buttonContatoNao.disabled = true;
        classListAdd(buttonContatoSim, '-loading');
        await sendForm();
    });

    document.querySelectorAll('.-roof-reset').forEach(el => {
        el.addEventListener('click', () => {
            formReset();
        });
    });
});